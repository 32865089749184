var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { createTextMaskInputElement, conformToMask } from "text-mask-core";
var MaskedInput = /** @class */ (function (_super) {
    __extends(MaskedInput, _super);
    function MaskedInput(props) {
        var _this = _super.call(this, props) || this;
        Object.defineProperty(_this, "textMaskInputElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "inputElement", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        _this.onChange = _this.onChange.bind(_this);
        return _this;
    }
    Object.defineProperty(MaskedInput.prototype, "initTextMask", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a = this, props = _a.props, value = _a.props.value;
            this.textMaskInputElement = createTextMaskInputElement(__assign(__assign({}, props), { inputElement: this.inputElement }));
            this.textMaskInputElement.update(value);
        }
    });
    Object.defineProperty(MaskedInput.prototype, "componentDidMount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.initTextMask();
        }
    });
    Object.defineProperty(MaskedInput.prototype, "componentDidUpdate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.initTextMask();
        }
    });
    Object.defineProperty(MaskedInput.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            var props = __assign({}, this.props);
            delete props.mask;
            delete props.guide;
            delete props.pipe;
            delete props.placeholderChar;
            delete props.keepCharPositions;
            delete props.value;
            delete props.onChange;
            delete props.showMask;
            return (_jsx("input", __assign({}, props, { onChange: this.onChange, defaultValue: this.props.value, ref: function (inputElement) { return (_this.inputElement = inputElement); } })));
        }
    });
    Object.defineProperty(MaskedInput.prototype, "onChange", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            this.textMaskInputElement.update();
            if (typeof this.props.onChange === "function") {
                this.props.onChange(event);
            }
        }
    });
    return MaskedInput;
}(React.Component));
export default MaskedInput;
var nonCharactersListMaker = function (mask) {
    // get string (non-regex) symbols in mask
    return mask
        .map(function (item, i) {
        if (typeof item === "string") {
            if (i === 0 || typeof mask[i - 1] !== "string") {
                return i;
            }
        }
        return -1;
    })
        .filter(function (item) { return item > 0; });
};
// this function makes mask that properly handles DELETE and BACKSPACE
// https://github.com/text-mask/text-mask/issues/230
export function maskMaker(mask) {
    var nonCharactersList = nonCharactersListMaker(mask);
    return function (raw) {
        var length = raw.length;
        var nonDefault = nonCharactersList.some(function (characterIndex) {
            return characterIndex === length;
        });
        return nonDefault ? mask.slice(0, length) : mask;
    };
}
export function conform(value, mask) {
    return conformToMask(value, mask, {
        guide: false,
    }).conformedValue;
}
