import { getContext } from "./utils";
import { Subject } from "rxjs";
var AppState = /** @class */ (function () {
    function AppState(window, document, namespace) {
        if (namespace === void 0) { namespace = "default"; }
        Object.defineProperty(this, "__raw_data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.__raw_data = getContext(window, document, namespace); // TODO Lazy load
    }
    Object.defineProperty(AppState.prototype, "getState", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            // automatically assign all keys from raw data to current object
            Object.keys(this.__raw_data).map(function (k) {
                _this[k] = _this.__raw_data[k];
            });
            return this;
        }
    });
    return AppState;
}());
export { AppState };
var appBus = new Subject();
export function getAppBus() {
    return appBus;
}
