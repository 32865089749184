var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var ErrorMessage = function (props) {
    var errors = props.errors, containerClassName = props.containerClassName, errorClassName = props.errorClassName;
    if (!Array.isArray(errors)) {
        return null;
    }
    return (_jsx("div", __assign({ className: containerClassName !== undefined ? containerClassName : "b-form-error" }, { children: errors
            .filter(function (err) { return err; })
            .map(function (error, i) {
            return (_jsx("div", __assign({ className: errorClassName !== undefined ? errorClassName : "b-form-error" }, { children: error.message }), i));
        }) })));
};
export default ErrorMessage;
